<template>
  <div class="columns" v-if="hasRsomComputed">
    <div class="column is-one-third">
      <card-component
        title="1. Display individuals"
        class="tile is-child"
        :displayHelp="$options.name"
      >
        <validation-observer v-slot="{ handleSubmit }">
          <b-field
              label="Plot type"
            >
               <b-select
                  v-model="plotType"
                  expanded
                >
                  <option value='color'>Color</option>
                  <option value='boxplot'>Boxplot</option>
                  <option value='names'>Names</option>
                  <option value='pie'>Pie</option>
                </b-select>
            </b-field>
            <div v-if="plotType!=='names'" >
              <validation-provider
              v-slot="{ errors }"
              :rules= {required:true}
              name="Variable"
            >
                <dataset-select-tag
                  ref="dataset"
                  label='Variable dataset'
                  :dataset.sync="datasetnew"
                  :varname.sync="variable"
                  :vartype="vartype"
                  v-model="variable"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                  :maxtags="maxTags"
                  @select="option => selectVarname(option)"
                  @reset="resetVarname('variable')"
                  icon="color" />
              </validation-provider>
            </div>
          <hr/>
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click.prevent="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-cogs"></i></span>
              <span> Explore individuals </span>
            </b-button>
          </div>
          </validation-observer>
        </card-component>
    </div>
    <div class="column">
      <card-component title="Explore individuals">
      </card-component>
      <error :type='"notifications"' />
      <card-no-result v-if="!resultsLoaded"/>
      <card-component  v-else-if="hasResults">
        <plotly
          v-if="graphicals.length===1"
          :data="graphicals[0]"
        ></plotly>
        <plotly-list
          v-else-if="graphicals.length >1"
          :data="graphicals"
        ></plotly-list>
        <png v-else-if="png" :data=png />
        <nav class="level" >
          <div class="level-left" />
          <div class="buttons level-right">
            <r-save-for-report
              label="Add to report"
              :objectName="objectSom"
              :params="params"
            />
          </div>
        </nav>
      </card-component>
    </div>
  </div>
</template>

<script>
import { ranalysis } from '@/mixins/ranalysis'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useUtilsStore } from '@/stores/utils'

export default {
  name: 'RPlotSomInd',
  props: {
    objectSom: {
      type: String,
      default: ''
    },
    datasetName: {
      type: String,
      default: ''
    }
  },
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'dataset-select-tag': () => import('@/components/ui/DatasetSelectTag.vue'),
    'png': () => import('@/components/rresults/PNG.vue'),
    'r-save-for-report': () => import('@/components/RSaveForReport.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      plotType: 'color',
      datasetnew: '',
      variable: [],
      maxTags: 1,
      vartype: 'quanti'
    }
  },
  watch: {
    plotType: function () {
      switch (this.plotType) {
        case 'names':
          this.datasetnew = ''
          this.maxTags = 1
          break
        case 'color':
          this.datasetnew = ''
          this.vartype = 'quanti'
          this.maxTags = 1
          break
        case 'boxplot':
          this.datasetnew = ''
          this.vartype = 'quanti'
          this.maxTags = 25
          break
        case 'pie':
          this.datasetnew = ''
          this.vartype = 'quali'
          this.maxTags = 1
          break
      }
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const utilsStore = useUtilsStore()
    return { workspaceStore, utilsStore }
  },
  computed: {
    datasetsWithNumerical: function () {
      return this.workspaceStore.datasetsWithNumerical
    },
    datasetsWithCategorical: function () {
      return this.workspaceStore.datasetsWithCategorical
    },
    allVarnames: function () {
      return this.utilsStore.allVarnames
    },
    hasRsomComputed: function () {
      if (this.datasetName === null) {
        return false
      } else {
        return true
      }
    },
    params: function () {
      let params = {
        'func_name': 'r_plotsomind',
        'datasetName': this.objectSom,
        'out_graph': true,
        'type': this.plotType
      }
      if (this.plotType !== 'names') {
        params['datasetNew'] = this.datasetnew
        if (this.plotType !== 'boxplot') {
          params['variable'] = this.variable[0]
        } else {
          params['variable'] = this.variable
        }
      }
      return params
    }
  },
  methods: {
    selectVarname: function (value) {
      this.variable = value
    }
  }
}
</script>
